import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
import Rule from './rule.vue';
let ConfigRule = class ConfigRule extends Vue {
    constructor() {
        super(...arguments);
        this.visible = false;
    }
    onChangeValue(v) {
        this.visible = v;
    }
    closeConfigRule() {
        this.$emit('input', false);
    }
};
__decorate([
    Prop()
], ConfigRule.prototype, "value", void 0);
__decorate([
    Watch('value')
], ConfigRule.prototype, "onChangeValue", null);
ConfigRule = __decorate([
    Component({
        name: 'ConfigRule',
        components: {
            Rule,
        },
    })
], ConfigRule);
export default ConfigRule;
