import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let Rule = class Rule extends Vue {
    constructor() {
        super(...arguments);
        this.dingTalkCheck = [
            {
                title: '登录钉钉管理后台，进入工作台-自建应用，添加应用。',
                content: '钉钉后台管理地址：',
                url: 'https://oa.dingtalk.com/index.htm#/login',
                img: require('./images/01.png'),
            },
            {
                title: '填写应用的基本信息，填写访问地址。',
                content: '钉钉后台管理地址：',
                url: '',
                img: require('./images/02.png'),
            },
            {
                title: '应用首页链接：',
                // eslint-disable-next-line
                content: '云枢系统移动端访问地址，\
      格式为：http://SERVER/mobile?corpId=$CORPID$&agentId=xxx，其中，\
      XXX为当前应用的AgentId，创建后获取 服务器出口IP：云枢系统的IP地址，\
      每个IP只能在一个企业中使用 PC端首页地址：云枢系统访问PC端的地址，\
      格式：http://SERVER?corpId=$CORPID$&agentId=xxx 管理后台地址：\
      云枢系统访问PC端的地址，格式：http://SERVER/admin',
                url: '',
                img: require('./images/03.png'),
            },
            {
                // eslint-disable-next-line
                title: '生成应用，获取Appkey、APPSecret和AgentId，\
      将AgentId填写到地址中，更新应用，并将信息填写到云枢系统“钉钉集成”中。',
                content: '',
                url: '',
                img: require('./images/04.png'),
            },
            {
                title: '开通企业应用的通讯录权限：企业应用-权限管理-查看详情',
                content: '',
                url: '',
                img: require('./images/05.png'),
            },
        ];
        this.portalSetting = [
            {
                title: 'CorpID、SSOsecret：钉钉企业的唯一标识，获取地址：工作台-自建应用-开发信息-开发账号管理-企业自用账号信息。',
                content: '',
                url: '',
                img: [require('./images/04.png')],
            },
            {
                // eslint-disable-next-line
                title: '扫码登录app ID、扫码登录appSecret、移动接入应用的标识，\
      获取地址：钉钉后台，工作台-自建应用--应用设置-移动介入应用-扫码登录使用的\
      app ID、appSecret获取',
                content: '',
                url: '',
                img: [require('./images/05.png'), require('./images/06.png')],
            },
        ];
        this.imgSrc = ''; // 图片连接
        this.isPreviewImage = false;
    }
    /**
     * 预览图片
    */
    previewImg(src) {
        this.isPreviewImage = true;
        this.imgSrc = src;
    }
};
Rule = __decorate([
    Component({
        name: 'Rule',
    })
], Rule);
export default Rule;
